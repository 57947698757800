@import url("@scania/tegel/dist/tegel/tegel.css");

.word-break {
  word-break: break-word;
}

body[data-env]::before {
  background-color: #ddd;
  border: 2px solid #fafafa;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  padding: 52px 50px 2px;
  content: attr(data-env);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1170;
  -webkit-transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  text-transform: uppercase;
}

.container {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100vh !important;
}

.main-content {
  flex: 1 !important;
}
